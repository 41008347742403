exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-adobe-commerce-subscriptions-jsx": () => import("./../../../src/pages/adobe-commerce-subscriptions.jsx" /* webpackChunkName: "component---src-pages-adobe-commerce-subscriptions-jsx" */),
  "component---src-pages-become-a-partner-jsx": () => import("./../../../src/pages/become-a-partner.jsx" /* webpackChunkName: "component---src-pages-become-a-partner-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-clients-jsx": () => import("./../../../src/pages/clients.jsx" /* webpackChunkName: "component---src-pages-clients-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-developers-jsx": () => import("./../../../src/pages/developers.jsx" /* webpackChunkName: "component---src-pages-developers-jsx" */),
  "component---src-pages-features-jsx": () => import("./../../../src/pages/features.jsx" /* webpackChunkName: "component---src-pages-features-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-integrations-jsx": () => import("./../../../src/pages/integrations.jsx" /* webpackChunkName: "component---src-pages-integrations-jsx" */),
  "component---src-pages-payment-providers-jsx": () => import("./../../../src/pages/payment-providers.jsx" /* webpackChunkName: "component---src-pages-payment-providers-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-request-demo-jsx": () => import("./../../../src/pages/request-demo.jsx" /* webpackChunkName: "component---src-pages-request-demo-jsx" */),
  "component---src-pages-review-subscribe-pro-jsx": () => import("./../../../src/pages/review-subscribe-pro.jsx" /* webpackChunkName: "component---src-pages-review-subscribe-pro-jsx" */),
  "component---src-pages-salesforce-commerce-cloud-subscriptions-jsx": () => import("./../../../src/pages/salesforce-commerce-cloud-subscriptions.jsx" /* webpackChunkName: "component---src-pages-salesforce-commerce-cloud-subscriptions-jsx" */),
  "component---src-pages-security-and-trust-jsx": () => import("./../../../src/pages/security-and-trust.jsx" /* webpackChunkName: "component---src-pages-security-and-trust-jsx" */),
  "component---src-pages-subscription-commerce-faq-jsx": () => import("./../../../src/pages/subscription-commerce-faq.jsx" /* webpackChunkName: "component---src-pages-subscription-commerce-faq-jsx" */),
  "component---src-pages-support-jsx": () => import("./../../../src/pages/support.jsx" /* webpackChunkName: "component---src-pages-support-jsx" */),
  "component---src-pages-thank-you-become-a-partner-jsx": () => import("./../../../src/pages/thank-you-become-a-partner.jsx" /* webpackChunkName: "component---src-pages-thank-you-become-a-partner-jsx" */),
  "component---src-pages-thank-you-contact-jsx": () => import("./../../../src/pages/thank-you-contact.jsx" /* webpackChunkName: "component---src-pages-thank-you-contact-jsx" */),
  "component---src-pages-thank-you-contact-sales-jsx": () => import("./../../../src/pages/thank-you-contact-sales.jsx" /* webpackChunkName: "component---src-pages-thank-you-contact-sales-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../../../src/pages/thank-you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */),
  "component---src-pages-thank-you-newsletter-jsx": () => import("./../../../src/pages/thank-you-newsletter.jsx" /* webpackChunkName: "component---src-pages-thank-you-newsletter-jsx" */),
  "component---src-pages-thank-you-request-demo-jsx": () => import("./../../../src/pages/thank-you-request-demo.jsx" /* webpackChunkName: "component---src-pages-thank-you-request-demo-jsx" */),
  "component---src-templates-category-template-jsx": () => import("./../../../src/templates/category-template.jsx" /* webpackChunkName: "component---src-templates-category-template-jsx" */),
  "component---src-templates-page-template-jsx": () => import("./../../../src/templates/page-template.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */),
  "component---src-templates-post-template-jsx": () => import("./../../../src/templates/post-template.jsx" /* webpackChunkName: "component---src-templates-post-template-jsx" */)
}

