/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from 'react';
import { AnimatePresence } from 'framer-motion';
import './src/css/index.css';

export const wrapPageElement = ({ element }) => <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>;
